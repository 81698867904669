import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import React, { memo } from 'react'

import { Center } from './Center'
import { Reviews } from './Reviews'

export interface Props {
  description?: string
  images: ImageProps[]
  reviews?: string[]
  thumbs: ImageProps[]
  title?: string
}

export const Staff = memo(function Staff({
  description,
  images,
  reviews,
  thumbs,
  title,
}: Props) {
  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Head row stretch wrap>
          <LeftSide>
            {title ? (
              <FadeInUp>
                <Title>{title}</Title>
              </FadeInUp>
            ) : null}

            {description ? (
              <FadeInUp>
                <Description
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </FadeInUp>
            ) : null}
          </LeftSide>

          <RightSide row space="between" wrap>
            <Media lessThan="desktopSmall">
              <Center images={images} thumbs={thumbs} />
            </Media>

            {thumbs && thumbs[0] ? (
              <Thumb>
                <Image {...thumbs[0]} />
              </Thumb>
            ) : null}

            {thumbs && thumbs[1] ? (
              <Thumb>
                <Image {...thumbs[1]} />
              </Thumb>
            ) : null}

            <Media lessThan="desktopSmall">
              {thumbs && thumbs[2] ? (
                <Thumb>
                  <Image {...thumbs[2]} />
                </Thumb>
              ) : null}
            </Media>
          </RightSide>
        </Head>

        <Media greaterThanOrEqual="desktopSmall">
          <Center images={images} thumbs={thumbs} />
        </Media>

        <Reviews reviews={reviews} />
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.danger};
  padding: 9.375rem 10.833vw 9.375rem 17.5vw;

  @media (max-width: 1199px) {
    padding: 3.75rem 1.5rem;
  }
`

const Head = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
`

const LeftSide = styled.div`
  width: 50%;
  border-left: 0.0625rem solid
    ${({ theme }) => theme.colors.variants.neutralLight5};
  padding-right: 3.75rem;
  padding-left: 1.25rem;

  @media (max-width: 1199px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0.9375rem;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 2.5rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 1.875rem;
  }
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.3125rem;
  line-height: 1.875rem;
  margin-top: 1.125rem;

  @media (max-width: 1199px) {
    margin-top: 1.6875rem;
  }
`

const RightSide = styled(FlexBox)`
  width: 50%;

  @media (max-width: 1199px) {
    width: 100%;
    margin-top: 1.5rem;

    .fresnel-container {
      width: 100%;
      margin-bottom: 1rem;
      &:last-of-type {
        margin-top: 1.5rem;
        margin-bottom: 0;

        > div {
          padding-bottom: 70%;
        }
      }

      > div {
        width: 100%;
      }
    }
  }
`

const Thumb = styled.div`
  width: calc(50% - 0.75rem);
  height: 38.9vh;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    width: calc(50% - 0.5rem);
    height: auto;
    padding-bottom: calc(50% - 0.5rem);
    /* &:last-of-type {
      width: 100%;
      margin-top: 1.5rem;
      padding-bottom: 60%;
    } */
  }
`
