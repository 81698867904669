import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useRef, useState } from 'react'

const AUTOPLAY_TIMER = 3000

export interface Props {
  images: ImageProps[]
  thumbs: ImageProps[]
}

export const Center = memo(function Center({ images, thumbs }: Props) {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState<boolean[]>([])
  const [nextLoaded, setNextLoaded] = useState<boolean[]>([])
  const [opacities, setOpacities] = useState<number[]>([])
  const timer = useRef<any>()

  const [sliderRef, galleryRef] = useKeenSlider({
    slides: images.length,
    loop: true,
    animationEnded(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      const newOpacities = s.track.details.slides.map((slide) => slide.portion)
      images.length > 1 ? setOpacities(newOpacities) : null
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true
    const newNextLoaded = [...nextLoaded]
    newNextLoaded[currentSlide + 1] = true

    setLoaded(newLoaded)
    setNextLoaded(newNextLoaded)

    timer.current = setInterval(() => {
      if (galleryRef) {
        galleryRef.current?.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, galleryRef, sliderRef])

  return (
    <Container row stretch wrap>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <LeftSide>
          <Slider ref={sliderRef}>
            {uniq(images).map((item, index) => (
              <Slide
                key={index}
                style={
                  index !== 0
                    ? {
                        opacity: opacities[index],
                      }
                    : { opacity: 1 }
                }
              >
                <Image
                  alt={loaded[index] || nextLoaded[index] ? item.alt : ''}
                  {...(loaded[index] || nextLoaded[index] ? item : null)}
                />
              </Slide>
            ))}
          </Slider>
        </LeftSide>

        <RightSide>
          <Media greaterThanOrEqual="desktopSmall">
            {thumbs && thumbs[2] ? (
              <Thumb>
                <Image {...thumbs[2]} />
              </Thumb>
            ) : null}
          </Media>
        </RightSide>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin-top: 1.5rem;
`

const LeftSide = styled.div`
  width: calc(68% - 1.5rem);
  margin-right: 1.5rem;
  position: relative;

  @media (max-width: 1199px) {
    width: 100%;
    margin-right: 0;
    padding-bottom: 70%;
  }
`

const RightSide = styled.div`
  width: 32%;

  @media (max-width: 1199px) {
    display: none;
  }
`

const Thumb = styled.div`
  height: 54.8vh;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Slider = styled.div`
  width: 100%;
  height: 100%;
  outline: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
`

const Slide = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 1s;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1s cubic-bezier(0, 0.55, 0.45, 1);
  }
`
